import './App.css';
import { RenderRout } from './router';

function App() {
  return (
    <RenderRout/>
  );
}

export default App;
