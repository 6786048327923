import React, { Suspense, Fragment, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
 import HeaderLayout from './Layout/HeaderLayout';
import Authenticated from './Guard/Authenticated';
import Guest from './Guard/Guest';
import BaseLayout from './Layout/BaseLayout';
import LoadScreen from './loaderScreen';

export function RenderRout() {
  
    return (
      <>
       <Router>
          <Suspense fallback={<LoadScreen/>}
          >
              <Routes>
              {routes?.map((route, i) => {
                const Guard = route?.guard || Fragment;
                const Layout = route?.layout || Fragment;
                const Component = route?.element;
                return (
                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    element={
                      <Guard>
                        <Layout >
                          <Component />
                          </Layout>
                      </Guard>
                    }
                  />
                );
              })}
            </Routes>
          </Suspense>
        </Router>
         </>
    )
  
  }

  const routes = [
    //Auth Pages
    {
      guard: Guest,
      layout:BaseLayout,
      exact: true,
      path: '/',
      element: lazy(() => import('./Component/Auth/Login'))
    },

     //Dashboard

     {
      guard: Authenticated,
      layout:HeaderLayout,
      exact: true,
      path: '/admin/dashborad',
      element: lazy(() => import('./Component/Pages/Dashboard'))
    },

    //Resturent
    {
      guard: Authenticated,
      layout:HeaderLayout,
      exact: true,
      path: '/admin/restaurant',
      element: lazy(() => import('./Component/Pages/Restaurant/Index'))
    },

    //User

    {
      guard: Authenticated,
      layout:HeaderLayout,
      exact: true,
      path: '/admin/users',
      element: lazy(() => import('./Component/Pages/Users/Index'))
    },
    {
      guard: Authenticated,
      layout:HeaderLayout,
      exact: true,
      path: '/admin/users/history/:id',
      element: lazy(() => import('./Component/Pages/Users/UserHistory'))
    },


    //Driver

    {
      guard: Authenticated,
      layout:HeaderLayout,
      exact: true,
      path: '/admin/drivers',
      element: lazy(() => import('./Component/Pages/Driver/Index'))
    },
    //global category
    {
      guard: Authenticated,
      layout:HeaderLayout,
      exact: true,
      path: '/admin/category',
      element: lazy(() => import('./Component/Pages/Global_category/Index'))
    },
    

   //logo
   {
    guard: Authenticated,
    layout:HeaderLayout,
    exact: true,
    path: '/admin/logo',
    element: lazy(() => import('./Component/Pages/Logo/Index'))
  },
//Menu
{
  guard: Authenticated,
  layout:HeaderLayout,
  exact: true,
  path: '/admin/restaurant/menu/:id',
  element: lazy(() => import('./Component/Pages/Menu/Index'))
},
  {
    guard: Authenticated,
    layout:HeaderLayout,
    exact: true,
    path: '/admin/catagory',
    element: lazy(() => import('./Component/Pages/Catagory/Index'))
  },
  
    ///404 Page

{
  layout:BaseLayout,
  exact: true,
  path: '*',
  element: lazy(() => import('./Component/PageNotFound'))
  
},
]